import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { LoadingService } from '@services/loading.service';
import { Dialog } from '@angular/cdk/dialog';
import { IMaskDirective } from 'angular-imask';
import { ToastService } from '@services/toast.service';
import { FeedbackFormsService } from '@technokratos-public/services/feedback-forms.service';
import { SharedPublicFeedbackModule } from '@technokratos-public/components/modals/feedback/shared-public-feedback.module';
import { textMask } from '@utils/masks';
import { AgreementComponent } from '@technokratos-public/components/ui/agreement/agreement.component';
import { FormFieldInfoComponent } from '@ui/form-field-info/form-field-info.component';
import { FormFieldErrorComponent } from '@ui/form-field-error/form-field-error.component';
import { FormFieldTextCounterComponent } from '@ui/form-field-text-counter/form-field-text-counter.component';

@Component({
	standalone: true,
	selector: 'tk-partner-form-modal',
	templateUrl: './partner-form-modal.component.html',
	styleUrls: ['./partner-form-modal.component.sass'],
	providers: [FeedbackFormsService, LoadingService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		SharedPublicFeedbackModule,
		IMaskDirective,
		AgreementComponent,
		FormFieldInfoComponent,
		FormFieldErrorComponent,
		FormFieldTextCounterComponent,
	],
})
export class PartnerFormModalComponent {
	public loading$ = inject(LoadingService);
	protected readonly textMask = textMask;
	private service = inject(FeedbackFormsService);
	private toaster = inject(ToastService);
	private fb = inject(FormBuilder);
	private dialog = inject(Dialog);
	nameMaxLength = 80;
	companyMaxlength = 100;
	positionMaxLength = 130;
	contactMaxlength = 100;
	offerMaxlength = 1000;
	form = this.fb.nonNullable.group({
		name: ['', [Validators.required, Validators.maxLength(this.nameMaxLength)]],
		company: ['', [Validators.required, Validators.maxLength(this.companyMaxlength)]],
		position: ['', [Validators.required, Validators.maxLength(this.positionMaxLength)]],
		contact: ['', [Validators.required, Validators.maxLength(this.contactMaxlength)]],
		offer: ['', [Validators.required, Validators.maxLength(this.offerMaxlength)]],
	});

	constructor() {
		this.loading$.next(false);
	}

	onInput(event: Event, field: string) {
		this.form.get(field)?.setValue((event.target as HTMLInputElement).value);
	}

	submit(formRef: FormGroupDirective) {
		if (this.form.invalid) {
			return;
		}

		this.loading$.next(true);
		this.service.createPartnerRequest(this.form.getRawValue()).subscribe({
			next: () => {
				formRef.resetForm();
				this.loading$.next(false);
				this.dialog.closeAll();
				this.toaster.success('Успешно отправлено');
			},
			error: () => {
				this.loading$.next(false);
				this.dialog.closeAll();
				this.toaster.error('Ошибка');
			},
		});
	}
}
