<tk-modal-header />
<div>
	<tk-arrow-back />
	<form (ngSubmit)="submit(formRef)" #formRef="ngForm" [formGroup]="form" autocomplete="off" class="form">
		<h2 class="form__header">Я из медиа</h2>
		<section class="form__fields">
			<tk-form-field>
				<tk-label>Как нам лучше к вам обращаться?</tk-label>
				<input
					tkInput
					formControlName="name"
					type="text"
					[imask]="textMask"
					placeholder="Дмитрий Иванов"
					(input)="onInput($event, 'name')"
				/>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('name')?.errors" />
					<tk-form-field-text-counter
						[currentCount]="form.get('name')?.value?.length || 0"
						[maxCount]="nameMaxLength"
					/>
				</tk-form-field-info>
			</tk-form-field>

			<tk-form-field>
				<tk-label>Какое медиа вы представляете?</tk-label>
				<input
					tkInput
					formControlName="media"
					type="text"
					placeholder='Журнал "GQ Россия"'
					(input)="onInput($event, 'media')"
				/>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('media')?.errors" />
					<tk-form-field-text-counter
						[currentCount]="form.get('media')?.value?.length || 0"
						[maxCount]="fieldMaxLength"
					/>
				</tk-form-field-info>
			</tk-form-field>

			<tk-form-field>
				<tk-label>Ваши контактные данные</tk-label>
				<input
					tkInput
					formControlName="contact"
					type="text"
					placeholder="Контактные данные"
					(input)="onInput($event, 'contact')"
				/>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('contact')?.errors" />
					<tk-form-field-text-counter
						[currentCount]="form.get('contact')?.value?.length || 0"
						[maxCount]="fieldMaxLength"
					/>
				</tk-form-field-info>
			</tk-form-field>

			<div>
				<h3 class="label">О чём хотели бы поговорить?</h3>
				<tk-form-field>
					<textarea
						tkInput
						formControlName="theme"
						type="text"
						placeholder="Хотим включить вас в рейтинг 10 лучших IT-компаний России"
					></textarea>
					<tk-form-field-info class="info">
						<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('theme')?.errors" />
						<tk-form-field-text-counter
							[currentCount]="form.get('theme')?.value?.length || 0"
							[maxCount]="themeMaxLength"
						/>
					</tk-form-field-info>
				</tk-form-field>
			</div>

			<tk-agreement />

			<tk-button-group class="tk-button-group">
				<button [attr.disabled]="(loading$ | async) || null" type="submit">Отправить</button>
			</tk-button-group>
		</section>
	</form>
</div>
