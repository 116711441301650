import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DialogService } from '@services/dialog.service';
import { reachGoalMetrica } from '@utils/yandex-metrica';
import { EmployeeFormModalComponent } from '@technokratos-public/components/modals/feedback/employee-form-modal/employee-form-modal.component';
import { PartnerFormModalComponent } from '@technokratos-public/components/modals/feedback/partner-form-modal/partner-form-modal.component';
import { ProjectFormModalComponent } from '@technokratos-public/components/modals/feedback/project-form-modal/project-form-modal.component';
import { MediaFormModalComponent } from '@technokratos-public/components/modals/feedback/media-form-modal/media-form-modal.component';

const FeedbackModalType = {
	employee: EmployeeFormModalComponent,
	partner: PartnerFormModalComponent,
	project: ProjectFormModalComponent,
	media: MediaFormModalComponent,
};
type FeedbackKeys = keyof typeof FeedbackModalType;
type FeedbackValues = (typeof FeedbackModalType)[FeedbackKeys];

@Component({
	standalone: true,
	selector: 'tk-feedback',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		EmployeeFormModalComponent,
		PartnerFormModalComponent,
		ProjectFormModalComponent,
		MediaFormModalComponent,
	],
})
export class FeedbackComponent {
	feedbackModalType = FeedbackModalType;
	reachGoalMetrica = reachGoalMetrica();

	private dialogService = inject(DialogService);

	openFeedbackForm(modalComponent: FeedbackValues) {
		switch (modalComponent) {
			case FeedbackModalType.project: {
				this.reachGoalMetrica('SALES');
				break;
			}
			case FeedbackModalType.media: {
				this.reachGoalMetrica('MEDIA');
				break;
			}
			case FeedbackModalType.partner: {
				this.reachGoalMetrica('PARTNER');
				break;
			}
			case FeedbackModalType.employee: {
				this.reachGoalMetrica('CAREER');
				break;
			}
		}

		this.dialogService.openDialog(modalComponent, {
			maxWidth: '1400px',
			backdropClass: 'cdk-overlay-backdrop--black',
		});
	}
}
