<tk-modal-header />
<div>
	<tk-arrow-back />
	<form (ngSubmit)="submit(formRef)" #formRef="ngForm" [formGroup]="form" autocomplete="off" class="form">
		<h2 class="form__header">Хочу стать партнёром</h2>

		<section class="form__fields">
			<tk-form-field>
				<tk-label>Как нам лучше к вам обращаться?</tk-label>
				<input
					tkInput
					[imask]="textMask"
					formControlName="name"
					type="text"
					placeholder="Дмитрий Иванов"
					(input)="onInput($event, 'name')"
				/>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('name')?.errors" />
					<tk-form-field-text-counter
						[currentCount]="form.get('name')?.value?.length || 0"
						[maxCount]="nameMaxLength"
					/>
				</tk-form-field-info>
			</tk-form-field>

			<tk-form-field>
				<tk-label>Из какой вы компании?</tk-label>
				<input
					tkInput
					formControlName="company"
					type="text"
					placeholder="Яндекс"
					(input)="onInput($event, 'company')"
				/>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('company')?.errors" />
					<tk-form-field-text-counter
						[currentCount]="form.get('company')?.value?.length || 0"
						[maxCount]="companyMaxlength"
					/>
				</tk-form-field-info>
			</tk-form-field>
			<tk-form-field>
				<tk-label>За что вы в ней отвечаете?</tk-label>

				<input
					tkInput
					formControlName="position"
					type="text"
					placeholder="За разработку"
					(input)="onInput($event, 'position')"
				/>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('position')?.errors" />
					<tk-form-field-text-counter
						[currentCount]="form.get('position')?.value?.length || 0"
						[maxCount]="positionMaxLength"
					/>
				</tk-form-field-info>
			</tk-form-field>

			<tk-form-field>
				<tk-label>Что хотите нам предложить?</tk-label>
				<input
					tkInput
					formControlName="offer"
					type="text"
					placeholder="Сотрудничество"
					(input)="onInput($event, 'offer')"
				/>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('offer')?.errors" />
					<tk-form-field-text-counter
						[currentCount]="form.get('offer')?.value?.length || 0"
						[maxCount]="offerMaxlength"
					/>
				</tk-form-field-info>
			</tk-form-field>

			<tk-form-field>
				<tk-label>Ваши контактные данные</tk-label>
				<input
					tkInput
					formControlName="contact"
					type="text"
					placeholder="Контактные данные"
					(input)="onInput($event, 'contact')"
				/>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('contact')?.errors" />
					<tk-form-field-text-counter
						[currentCount]="form.get('contact')?.value?.length || 0"
						[maxCount]="contactMaxlength"
					/>
				</tk-form-field-info>
			</tk-form-field>

			<tk-agreement />

			<tk-button-group class="tk-button-group">
				<button [attr.disabled]="(loading$ | async) || null" type="submit">Отправить</button>
			</tk-button-group>
		</section>
	</form>
</div>
