import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FeedbackFormsService } from '@technokratos-public/services/feedback-forms.service';
import { ModalHeaderComponent } from '@technokratos-public/components/modals/modal-header/modal-header.component';
import { ArrowBackComponent } from '@technokratos-public/components/modals/arrow-back/arrow-back.component';
import { ProjectComponent } from '@technokratos-public/components/ui/project/project.component';

@Component({
	standalone: true,
	selector: 'tk-project-form-modal',
	templateUrl: './project-form-modal.component.html',
	styleUrls: ['./project-form-modal.component.sass'],
	providers: [FeedbackFormsService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ProjectComponent, ModalHeaderComponent, ArrowBackComponent],
})
export class ProjectFormModalComponent {}
